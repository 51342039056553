import React, {useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import MenuIcon from '../../static/icons/menu.svg'
import CloseIcon from '../../static/icons/close.svg'
import headerStyles from './header.module.css'

export default function Header() {

    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "images/logo-E.png"}) {
                id
                childImageSharp {
                    fixed(width: 55, height: 55) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const sources = [{...data.file.childImageSharp.fixed}]

    function handleMenuButtonClick() {
        setIsMenuOpen(prevState => !prevState)
    }

    return (
        <header className={headerStyles.header}>
            <div className={headerStyles.navbarContainer}>
                <div className={headerStyles.navbarBrand}>
                    <Link to='/' className={headerStyles.headerHomeLink}>
                        <Img fixed={sources} alt='logo de Eduardo Ayora' />
                    </Link>
                    <button onClick={handleMenuButtonClick} className={headerStyles.headerBurguerButton}>
                        {isMenuOpen ? 
                            <CloseIcon className={headerStyles.headerSvg} /> : <MenuIcon className={headerStyles.headerSvg} />}
                    </button>
                </div>
                <div className={isMenuOpen ? `${headerStyles.navbarMenu} ${headerStyles.visible}` : headerStyles.navbarMenu}>
                    <Link className='link-button' to='/contacto'>Contacto</Link>
                </div>
            </div>
        </header>
    )
}
