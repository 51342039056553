import React from 'react'
import Header from './header'
import Footer from './footer'
import layoutStyles from './layout.module.css'

export default function Layout({children}) {
    return (
        <div className={layoutStyles.layoutContainer}>
            <Header />
            <div className={layoutStyles.layoutContent}>
                {children}
            </div>
            <Footer />
        </div>
    )
}
