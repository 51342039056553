import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import footerStyles from './footer.module.css'

library.add(fab, far)

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: {eq: "images/logo-E-opuesto.png"}) {
                id
                childImageSharp {
                    fixed(width: 55, height: 55) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const sources = [{...data.file.childImageSharp.fixed}]

    return (
        <footer>
            <Link to='/'>
                <Img fixed={sources} alt='logo de Eduardo Ayora' />
            </Link>
            <div className={footerStyles.socialIcons}>
                <a href='https://www.instagram.com/eduardoayora/' target='_blank' rel="noreferrer" className={footerStyles.socialButton}>
                    <FontAwesomeIcon icon={['fab', 'instagram']} className='fa-lg' />
                </a>
                <a href='https://github.com/EduardoAyora' target='_blank' rel="noreferrer" className={footerStyles.socialButton}>
                    <FontAwesomeIcon icon={['fab', 'github']} className='fa-lg' />
                </a>
            </div>
            <div className={footerStyles.copyright}>
                Desarrollado por mí <FontAwesomeIcon icon={['far', 'copyright']} /> 2020
            </div>
            <ul className={footerStyles.footerCredits}>
                <li>Icons made by <a href="https://creativemarket.com/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></li>
                <li>Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></li>
            </ul>
        </footer>
    )
}
